import React, {useEffect, useState} from "react";
import {Box, Button, Dialog, DialogContent, IconButton, Stack, Typography} from "@mui/material";
import questionType from "../assets/data/typeMap";
import {QuestionSwitch} from "./Questions/QuestionSwitch";
import {firestore} from "../firebase/clientApp";
import {useParams} from "react-router-dom";
import {Close} from "@mui/icons-material";


export const PinnedQuestions = ({questions}) => {
    const [selected, setSelected] = useState('')
    const {id} = useParams()

    const [elementPlaying, setElementPlaying] = useState({})

    useEffect(() => {
        selected !== '' ? getElementPlaying() : setElementPlaying({})
    }, [selected]);

    function getElementPlaying() {
        firestore.collection('users/plesh/events/'+id+'/queue').doc(selected).onSnapshot(s => {
            setElementPlaying({...s.data(), id: s.id})
            return s.data()
        })
    }

    return (
        <Box>
            {selected !== '' && <Dialog open={selected !== ''} onClose={() => setSelected('')} maxWidth={'md'} fullWidth={true}>
                <DialogContent>
                    <Box sx={{textAlign:'right'}}>
                        <IconButton onClick={() => setSelected('')}>
                            <Close/>
                        </IconButton>
                    </Box>
                    <Typography textAlign={'center'} variant={'h5'}>
                        {elementPlaying.domanda}
                    </Typography>
                    <QuestionSwitch elementPlaying={elementPlaying}/>
                </DialogContent>
            </Dialog>}
            <Stack width={'90%'} spacing={4} margin={'auto'}>
                {
                    questions.docs.sort((a, b) => a.data().position - b.data().position).map((q, i) =>
                        <Button key={i} variant={'home-question'} startIcon={questionType[q.data().tipo].icon} onClick={() => setSelected(q.id)}>
                            {q.data().domanda}
                        </Button>
                    )
                }
            </Stack>
        </Box>
    )
}